<template>
  <div>
    <v-layout>
      <div class="pc-menu">
        <div class="menu-list">
          <ul>
            <template v-for="item in ProjectAccountabilityPageList">
              <li v-if="item.condition(project)" :key="item.id">
                <!-- toggle .active / .done / .disabled -->
                <a
                  href="javascript:;"
                  :class="tab == item.id ? 'active' : ''"
                  @click="changeTab(item.id)"
                >
                  <em>
                    <i :class="item.icon"></i>
                  </em>
                  {{item.name}}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="boxapp">
        <!-- HERO -->
        <div class="box-main" v-if="tab == ProjectAccountabilityPage.Hero">
          <div class="section hero-preview">
            <v-layout row justify-space-between wrap>
              <div class="page-title">
                <v-icon size="24" class="mr-3">fal fa-bullhorn</v-icon>
                <h3>Hero - Destaque Principal</h3>
              </div>
              <div>
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-5 ml-auto mr-0"
                  large
                  @click="saveHero()"
                >Salvar</v-btn>
              </div>
            </v-layout>
            <ProjectAccountabilityHeroForm :projectHero="project.projectAccountabilityHeroConfig" />
          </div>
        </div>
        <!-- ATIVIDADES -->
        <div class="box-main" v-else-if="tab == ProjectAccountabilityPage.Schedules">
          <div class="section project-goals">
            <div class="page-title mb-3">
              <v-icon size="24" class="mr-3">fal fa-book</v-icon>
              <h3>Atividades</h3>
            </div>
            <template v-for="schedule in project.scheduleList">
              <ProjectSchedule
                :key="schedule.id"
                :schedule="schedule"
                :reloadGoals="reloadGoals"
                @accountability="openModalAccountabilityActivities"
              />
            </template>
          </div>
        </div>
        <!-- METAS -->
        <div class="box-main" v-else-if="tab == ProjectAccountabilityPage.Goals">
          <div class="section project-goals">
            <div class="page-title mb-3">
              <v-icon size="24" class="mr-3">fal fa-globe-americas</v-icon>
              <h3>Metas</h3>
            </div>
            <template v-for="(goal, index) in project.goalList">
              <ProjectGoal
                :key="goal.id"
                :goal="goal"
                :reloadGoals="reloadGoals"
                :frequency="project.goalFrequency"
                :isEditable="index != 0"
                @edit="editGoal"
                @remove="deleteGoal"
                @accountability="openModalAccountability"
                @accountabilityPhotos="openModalAccountabilityPhotos"
                @refresh="loadProjectById"
              />
            </template>
          </div>
        </div>
        <!-- NOTIFICAÇÕES -->
        <div class="box-main" v-else-if="tab == ProjectAccountabilityPage.Notifications">
          <div class="section project-goals">
            <div class="page-title mb-3">
              <v-icon size="24" class="mr-3">fal fa-paper-plane</v-icon>
              <h3>Notificações</h3>
            </div>
            <ProjectNotificationsPeriod
              :goalList="project.goalList"
              :frequency="project.goalFrequency"
              @refresh="loadProjectById"
            />
          </div>
        </div>
      </div>
      <div class="box-help-spacer sm"></div>
      <HelpBox :text="helpBoxText" class="sm" />
    </v-layout>
    <ModalAccountability
    :key="goalsValuesModal.id"
      :indexFrequency="indexFrequency"
      @save="loadProjectById()"
      ref="modalAccountability"
    />
    <ModalAccountabilityActivities @save="loadProjectById()" ref="modalAccountabilityActivities" />
    <NewGoals
      ref="newGoalsComponent"
      :project="project"
      :groupType="groupTypeList"
      :groupVerification="groupVerificationList"
      :numberOfFields="numberOfFields"
      v-model="project.goalList"
      @save="loadProjectById()"
    />
  </div>
</template>

<script type="plain/text">
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import FileService from "@/scripts/services/file.service.js";
import ProjectService from "@/scripts/services/project.service.js";
import HelpBox from "@/components/HelpBox.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import GoalsValues from "@/scripts/models/goalsValues.model";
import Project from "@/scripts/models/project.model";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import {
  GoalFrequency,
  GetGoalFrequencyName
} from "@/scripts/models/enums/goalFrequency.enum";
import {
  ProjectAccountabilityPage,
  ProjectAccountabilityPageList
} from "@/scripts/models/enums/projectAccountabilityPage.enum";
import { mapGetters } from "vuex";
import ModalAccountability from "@/views/app/project/modal/ModalAccountability.vue";
import ModalAccountabilityActivities from "@/views/app/project/modal/ModalAccountabilityActivities.vue";
import NewGoals from "@/components/NewGoals.vue";
import ProjectGoal from "@/components/ProjectGoal.vue";
import ProjectNotificationsPeriod from "@/components/ProjectNotificationsPeriod.vue";
import ProjectSchedule from "@/components/ProjectSchedule.vue";
import ProjectAccountabilityHeroForm from "@/components/ProjectAccountabilityHeroForm.vue";

export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    ModalAccountability,
    ModalAccountabilityActivities,
    NewGoals,
    ProjectAccountabilityHeroForm,
    ProjectGoal,
    ProjectNotificationsPeriod,
    ProjectSchedule
  },
  props: ["pageOptions"],
  computed: {
    ...mapGetters(["hasPermission"])
  },
  data() {
    return {
      tab: ProjectAccountabilityPage.Hero,
      helpBoxText: HelpBoxText.ProjectAccountability,
      ProjectAccountabilityPage: ProjectAccountabilityPage,
      ProjectAccountabilityPageList: ProjectAccountabilityPageList,
      fileService: new FileService(),
      project: new Project(),
      projectService: new ProjectService(),
      goalsValuesModal: new GoalsValues(),
      numberOfFields: 0,
      indexFrequency: 0,
      groupVerificationList: [],
      groupTypeList: [],
      reloadGoals: "",
      realized: 0,
      monthsFrequency: {
        1: "mensalmente",
        2: "bimestralmente",
        3: "trimestralmente"
      },
      months: { 1: "mês", 2: "bimestre", 3: "trimestre" }
    };
  },
  created() {
    this.pageOptions.changeClass("");
    this.loadProjectById();
    this.projectService.listAllGoalVerification(
      this.listAllGoalVerificationCallback
    );
    this.projectService.listAllGoalType(this.listAllGoalTypeCallback);
  },
  methods: {
    changeTab(current) {
      this.tab = current;
    },
    changeBreadCrumb() {
      let breadcrumbLevels = [
        { text: "Início" },
        { text: "Projetos", pathName: "-1" },
        { text: this.project.shortName },
        { text: "Prestação de Contas" }
      ];

      this.$emit("changeLevels", breadcrumbLevels);
    },
    // Goal
    editGoal(item) {
      this.isNewGoal = false;
      item.goalTypeId = parseInt(item.goalTypeId);
      item.goalVerificationId = parseInt(item.goalVerificationId);
      this.$refs.newGoalsComponent.open(item);
    },
    newGoal() {
      this.isNewGoal = true;
      let goal = new Goal(this.numberOfFields);
      goal.frequency = this.project.goalFrequency;
      this.$refs.newGoalsComponent.open(goal);
    },
    saveHero() {
      if (
        this.project.projectAccountabilityHeroConfig &&
        this.project.projectAccountabilityHeroConfig.heroFile &&
        this.project.projectAccountabilityHeroConfig.heroFile.path
      ) {
        this.projectService
          .saveAccountabilityHero(this.project.projectAccountabilityHeroConfig)
          .then(data => {
            if (data) {
              this.$store.commit(
                "SET_SNACKBAR_SUCCESS_MESSAGE",
                "Hero salvo com sucesso."
              );
            }
          });
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "É obrigatório o upload de uma imagem para hero"
        );
      }
    },
    deleteGoal(item) {
      this.$refs.newGoalsComponent.remove(item.id);
    },
    openModalAccountability(payload) {
      let { goal, goalValue } = payload;
      this.goalsValuesModal = goalValue;
      this.$nextTick(() => {
        this.$refs.modalAccountability.open(goal, goalValue);
      });      
    },
    openModalAccountabilityActivities(schedulePeriod) {
      this.$refs.modalAccountabilityActivities.open(schedulePeriod);
    },
    openModalAccountabilityPhotos(goalValue) {
      this.$refs.modalAccountabilityPhotos.open(goalValue);
    },
    loadProjectById() {
      var id = this.$route.query.projectId;
      this.projectService.findByIdWithGoal(id).then(data => {
        this.project = data;
        this.calculateFrequency();
        this.changeBreadCrumb();

        for (let i = 0; i < this.project.goalList.length; i++) {
          let frequency = this.project.goalList[i].frequency;
          let monthsCount = 1;
          this.project.goalList[i].goalsValuesList = this.project.goalList[
            i
          ].goalsValuesList.map(v => {
            let frequencyDesc = "-";
            if (!v.canceled) {
              frequencyDesc = `${monthsCount}º ${GetGoalFrequencyName(
                frequency
              )}`;
              monthsCount++;
            }
            return { ...v, frequencyDesc };
          });
        }
        this.reloadGoals = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "")
          .substr(0, 5);
      });
    },
    listAllGoalVerificationCallback(data) {
      this.groupVerificationList = data;
    },
    listAllGoalTypeCallback(data) {
      this.groupTypeList = data;
    },
    calculateFrequency() {
      this.project.goalFrequency = Number(this.project.goalList[0].frequency);
      var dateFrom = new Date(this.project.initialFinalDates[0]);
      var dateTo = new Date(this.project.initialFinalDates[1]);

      var months =
        (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 +
        (dateTo.getMonth() - dateFrom.getMonth());
      this.numberOfFields = Math.ceil(
        months == 0 ? 1 : months / Number(this.project.goalFrequency)
      );
    }
  }
};
</script>
