<template>
  <div>
    <div class="wrapper mb-5">
      <v-layout class="d-flex flex-column mb-5">
        <v-flex>
          <v-layout w-100 align-center>
            <v-flex shrink pr-5>
              <h2 class="text-gray-dark">{{ goal.name }}</h2>
              <span>
                Esperado no período:
                <b>{{ howMuchIsExpected | FormatNumber}}</b>
              </span>
              <!-- <br />
              <span>
                Objetivo final:
                <b>{{ goal.amount | FormatNumber}}</b>
              </span>-->
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex money grow xs-12 wrap>
              <v-layout align-center>
                <v-flex percent slider grow>
                  <v-slider
                    readonly
                    :value="formatterHelper.formatSlider(howMuchHasBeenRealized, howMuchIsExpected)"
                    thumb-color="#02ACB4"
                    thumb-label="always"
                    color="#02ACB4"
                  ></v-slider>
                </v-flex>
                <v-flex money-values shrink list-tools pl-3 v-if="isEditable">
                  <div>
                    <a @click="edit(goal)">
                      <i class="fal fa-pen"></i>
                    </a>
                    <a @click="openConfirmModal(goal)">
                      <i class="fal fa-trash"></i>
                    </a>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <hr class="sm" />
      <v-layout>
        <v-flex mt-2>
          <div class="goal-line w-100 d-flex flex-column">
            <h5>
              COMPROVAÇÃO POR:
              <b>{{ goal.goalVerification.name }}</b>
            </h5>

            <v-layout
              v-for="goalValue in goal.goalsValuesList"
              :key="goalValue.id + reloadGoals"
              class="d-flex flex-column"
              :class=" goalValue.statusId == GoalsValuesStatus.NotStarted ? 'status-disabled' : ''"
            >
              <v-layout class="first-line" wrap>
                <v-flex xs12 sm4 lg2>
                  <h4>
                    <b>{{ goalValue.frequencyDesc }}</b>
                  </h4>
                </v-flex>

                <v-flex xs12 sm4 lg2>
                  <h4>
                    <small>Início:</small>
                    <br />
                    {{goalValue.initialDate | FormatDate("L") }}
                  </h4>
                </v-flex>

                <v-flex xs12 sm4 lg2>
                  <h4>
                    <small>Fim:</small>
                    <br />
                    {{goalValue.finalDate | FormatDate("L") }}
                  </h4>
                </v-flex>

                <v-flex xs12 sm4 lg2>
                  <h4>
                    Meta:
                    <b>{{goalValue.value}}</b>
                  </h4>
                </v-flex>

                <v-flex xs12 sm4 lg2>
                  <h4>
                    Realizado:
                    <b>{{goalValue.realized ? goalValue.realized : 0}}</b>
                  </h4>
                </v-flex>

                <v-flex xs12 sm4 lg2 class="text-xs-right">
                  <v-btn
                    icon
                    v-if="goalValue.canceled || goalValue.statusId == GoalsValuesStatus.NotStarted"
                    :class="goalValue.canceled ? 'icon-hands-canceled' : 'accountability-button'"
                  >
                    <v-icon size="20">fal fa-hands-usd</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-else
                    :class="goalValue.statusId == GoalsValuesStatus.Late ? 'icon-hands-canceled' : 'accountability-button'"
                    @click="openAccountabilityModal(goal, goalValue)"
                  >
                    <v-icon size="20">fal fa-hands-usd</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-flex>
                <v-progress-linear
                  :buffer-value="formatterHelper.formatSlider(goalValue.realized, goalValue.value)"
                  height="3"
                  class="primary-accountability"
                ></v-progress-linear>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <AucAlert
      ref="alertComponent"
      title="Você tem certeza que deseja excluir essa meta?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirm"
    />
  </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import { format } from "date-fns";
import {
  GoalFrequency,
  GetGoalFrequencyName
} from "@/scripts/models/enums/goalFrequency.enum";
import AucAlert from "@/components/AucAlert.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import Permissions from "@/scripts/models/enums/permissions.enum";
import {
  GoalsValuesStatus,
  GetGoalsValuesDescription,
  GetGoalsValuesStatusClass
} from "@/scripts/models/enums/goalsValuesStatus.enum";
import ProjectService from "@/scripts/services/project.service";
export default {
  components: {
    AucAlert,
    Modal
  },
  props: ["goal", "frequency", "reloadGoals", "isEditable"],
  data() {
    return {
      valueDeterminate: 10,
      selectedGoal: null,
      selectedEmailGoal: null,
      GoalsValuesStatus: GoalsValuesStatus,
      GetGoalsValuesDescription: GetGoalsValuesDescription,
      GetGoalsValuesStatusClass: GetGoalsValuesStatusClass,
      GetGoalFrequencyName: GetGoalFrequencyName,
      formatterHelper: new FormatterHelper(),
      projectService: new ProjectService()
    };
  },
  computed: {
    howMuchHasBeenRealized() {
      if (this.goal && this.goal.goalsValuesList) {
        let result = this.goal.goalsValuesList.reduce((acc, value) => {
          if (
            value.statusId != GoalsValuesStatus.NotStarted &&
            !value.canceled
          ) {
            return acc + value.realized;
          }
          return acc;
        }, 0);
        return result;
      }
      return 0;
    },
    howMuchIsExpected() {
      if (this.goal && this.goal.goalsValuesList) {
        let result = this.goal.goalsValuesList.reduce((acc, value) => {
          if (
            value.statusId != GoalsValuesStatus.NotStarted &&
            !value.canceled
          ) {
            return acc + value.value;
          }
          return acc;
        }, 0);
        return result;
      }
      return 0;
    }
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    openConfirmModal(item) {
      this.selectedGoal = item;
      this.$refs.alertComponent.open();
    },
    confirm() {
      this.$emit("remove", this.selectedGoal);
    },
    openAccountabilityModal(goal, goalValue) {
      this.$emit("accountability", { goal, goalValue });
    }
  }
};
</script>