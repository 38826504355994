<template>
  <div>
    <Modal
      modalTitle="Prestação de Contas - Upload"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="show"
      @input="close"
    >
      <!-- body  -->
      <template>
        <v-container fluid pa-0>
          <v-layout px-4 py-3 row wrap>
            <v-flex>
              <InputTextArea
                ref="descriptionInput"
                type="text"
                textLabel="Descrição"
                v-model="schedulePeriod.description"
                labelAreaCharLimit="500"
                rows="5"
                place-holder="Escreva a descrição do documento"
              />
            </v-flex>
            <div class="d-flex xs4 align-end px-3">
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 ml-auto mr-0"
                large
                @click="save()"
              >Salvar</v-btn>
            </div>
          </v-layout>
          <ul class="list-accountability">
            <li
              v-for="(schedulePeriodFile, index) in schedulePeriod.schedulePeriodFiles"
              :key="schedulePeriodFile.id"
            >
              <InputSelect
                ref="fileTypesInput"
                :items="fileTypes"
                valueAttribute="id"
                textAttribute="name"
                v-model="schedulePeriodFile.schedulePeriodFileTypeId"
                unselectedText="Selecione tipo do arquivo"
                :useAnotherDefaultValue="true"
                class="mr-3 flex-grow-0"
                style="width:auto"
              ></InputSelect>
              <i
                :class="`fal fa-file-${
                  schedulePeriodFile.file ? 
                getLastArray(schedulePeriodFile.file.name.split('.')) == 'PDF' || getLastArray(schedulePeriodFile.file.name.split('.')) == 'pdf' ? 'pdf': 'image' : 'video' } text-red`"
              ></i>
              <h4
                class="text-color-dark flex-grow-1 font-weight-regular"
              >{{schedulePeriodFile.file ? schedulePeriodFile.file.name : schedulePeriodFile.video.name}}</h4>
              <a @click="removeFile(index)" class="ml-auto" title="Remover arquivo">
                <i class="fal fa-trash"></i>
              </a>
            </li>
          </ul>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <div class="d-flex xs7 px-3 align-center">
          <i class="fal fa-exclamation-triangle text-yellow alert-accountability"></i>
          Envie arquivos com nomes que indiquem seu conteúdo a fim de facilitar sua identificação.
        </div>
        <v-flex xs5>
          <div class="upload-bar-gallery w-100 pr-2">
            <label @click="openUploadVideo" class="ml-auto mr-4">
              <i class="fal fa-video"></i>
            </label>
            <label for="upload-file2" class="ml-auto mr-4">
              <i class="fal fa-cloud-upload"></i>
            </label>
            <input
              type="file"
              :id="'upload-file2'"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .html, .xlsx, .xls, .csv"
              @change="fileChanged($event)"
            />
          </div>
        </v-flex>
      </template>
    </Modal>
    <UploadVideo ref="videoComponent" @close="closeUploadVideo" @save="addVideo"></UploadVideo>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import UploadVideo from "@/components/UploadVideo.vue";
import SchedulePeriod from "@/scripts/models/schedulePeriod.model";
import SchedulePeriodFile from "@/scripts/models/schedulePeriodFile.model";
import File from "@/scripts/models/file.model";
import ProjectService from "@/scripts/services/project.service.js";
import { SchedulePeriodStatus } from "@/scripts/models/enums/schedulePeriodStatus.enum";
import { SchedulePeriodFileType, SchedulePeriodFileTypeArray } from "@/scripts/models/enums/schedulePeriodFileType.enum";
export default {
  components: {
    Modal,
    UploadVideo
  },
  props: {},
  data() {
    return {
      show: false,
      realized: "",
      schedulePeriod: new SchedulePeriod(),
      projectService: new ProjectService(),
      SchedulePeriodFileType: SchedulePeriodFileType,
      SchedulePeriodStatus: SchedulePeriodStatus,
      fileTypes: SchedulePeriodFileTypeArray
    };
  },
  computed: {},
  methods: {
    open(schedulePeriod) {
      this.loadSchedulePeriod(schedulePeriod.id);
      this.show = true;
    },
    close() {
      this.copy(new SchedulePeriod());
      this.show = false;
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let newFile = new File();
      newFile.name = file.name;
      let reader = new FileReader();
      reader.onload = function() {
        newFile.path = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
      var schedulePeriodFile = new SchedulePeriodFile();
      schedulePeriodFile.file = newFile;
      schedulePeriodFile.video = null;
      schedulePeriodFile.videoId = null;
      schedulePeriodFile.schedulePeriodId = this.schedulePeriod.id;
      this.schedulePeriod.schedulePeriodFiles.push(schedulePeriodFile);
    },
    addVideo(newVideo) {
      var schedulePeriodFile = new SchedulePeriodFile();
      schedulePeriodFile.video = newVideo;
      schedulePeriodFile.file = null;
      schedulePeriodFile.fileId = null;
      schedulePeriodFile.schedulePeriodId = this.schedulePeriod.id;
      schedulePeriodFile.schedulePeriodFileTypeId = SchedulePeriodFileType.Video;
      this.schedulePeriod.schedulePeriodFiles.push(schedulePeriodFile);
      this.show = true;
    },
    openUploadVideo() {
      this.$refs.videoComponent.open();
      this.show = false;
    },
    closeUploadVideo() {
      this.show = true;
    },
    loadSchedulePeriod(id) {
      this.projectService.findSchedulePeriodById(
        this.callbackFindSchedulePeriodById,
        id
      );
    },
    callbackFindSchedulePeriodById(data) {
      if (data != null) {
        this.copy(data);
      }
    },
    copy(schedulePeriod) {
      this.schedulePeriod = new SchedulePeriod();
      this.schedulePeriod.id = schedulePeriod.id;
      this.schedulePeriod.scheduleId = schedulePeriod.scheduleId;
      this.schedulePeriod.initialDate = schedulePeriod.initialDate;
      this.schedulePeriod.finalDate = schedulePeriod.finalDate;
      this.schedulePeriod.statusId = schedulePeriod.statusId;
      this.schedulePeriod.description = schedulePeriod.description;
      this.schedulePeriod.schedulePeriodFiles = [];
      if (schedulePeriod.schedulePeriodFiles) {
        for (let i = 0; i < schedulePeriod.schedulePeriodFiles.length; i++) {
          let schedulePeriodFile = {
            file: schedulePeriod.schedulePeriodFiles[i].file,
            video: schedulePeriod.schedulePeriodFiles[i].video,
            id: schedulePeriod.schedulePeriodFiles[i].id,
            fileId: schedulePeriod.schedulePeriodFiles[i].fileId,
            videoId: schedulePeriod.schedulePeriodFiles[i].videoId,
            schedulePeriodId:
              schedulePeriod.schedulePeriodFiles[i].schedulePeriodId,
            schedulePeriodFileTypeId:
              schedulePeriod.schedulePeriodFiles[i].schedulePeriodFileTypeId
          };
          this.schedulePeriod.schedulePeriodFiles.push(schedulePeriodFile);
        }
      }
    },
    save() {
      if (
        !this.schedulePeriod.description ||
        this.schedulePeriod.description.length < 50
      ) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "A descrição precisa ter pelo menos 50 caracteres."
        );
      } else {
        let incorrectFiles = this.schedulePeriod.schedulePeriodFiles.filter(
          x =>
            x.schedulePeriodFileTypeId != SchedulePeriodFileType.Document &&
            x.schedulePeriodFileTypeId != SchedulePeriodFileType.Image &&
            x.schedulePeriodFileTypeId != SchedulePeriodFileType.Video
        );
        if (incorrectFiles.length > 0) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Selecione o tipo do arquivo em que fez upload."
          );
        } else {
          this.schedulePeriod.statusId = SchedulePeriodStatus.Finished;
          this.projectService.saveSchedulePeriod(
            this.saveCallback,
            this.schedulePeriod
          );
        }
      }
    },
    saveCallback() {
      this.$emit("save");
      this.close();
    },
    removeFile(index) {
      this.schedulePeriod.schedulePeriodFiles.splice(index, 1);
    },
    getLastArray(array, n) {
      if (array == null) return void 0;
      if (n == null) return array[array.length - 1];
      return array.slice(Math.max(array.length - n, 0));
    }
  }
};
</script>