const ProjectAccountabilityPage = {
  Hero: 1,
  Goals: 2,
  Schedules: 3,
  Notifications: 4
};

const ProjectAccountabilityPageList = [
  {
    id: ProjectAccountabilityPage.Hero,
    name: "Hero",
    icon: "fal fa-bullhorn",
    condition: (project) => {
      return true;
    },
  },
  {
    id: ProjectAccountabilityPage.Goals,
    name: "Metas",
    icon: "fal fa-globe-americas",
    condition: (project) => {
      return project && project.goalList && project.goalList.length > 0;
    },
  },
  {
    id: ProjectAccountabilityPage.Schedules,
    name: "Atividades",
    icon: "fal fa-book",
    condition: (project) => {
      return project && project.scheduleList && project.scheduleList.length > 0;
    },
  },
  {
    id: ProjectAccountabilityPage.Notifications,
    name: "Notificações",
    icon: "fal fa-paper-plane",
    condition: (project) => {
      return true;
    },
  },
];

export { ProjectAccountabilityPage, ProjectAccountabilityPageList };
