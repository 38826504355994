<template>
  <div>
    <Modal
      :modalTitle="`Prestação de Contas - Upload (${frequencyDesc})`"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      :modalFooter="true"
      v-model="show"
      @input="close"
    >
      <!-- body  -->
      <template>
        <v-container fluid pa-0>
          <v-layout px-4 py-3 row wrap>
            <v-flex>
              <InputMoney
                ref="realizedInput"
                helpLabel="Somente números"
                textLabel="Realizado"
                place-holder="Valor Realizado"
                v-model="goalValue.realized"
                :precision="0"
                prefix
              />
              <InputText
                ref="titleInput"
                type="text"
                textLabel="Título"
                v-model="goalValue.title"
                labelCharLimit="50"
                place-holder="Escreva um título para a comprovação"
              />
              <InputTextArea
                ref="descriptionInput"
                type="text"
                textLabel="Descrição"
                v-model="goalValue.description"
                labelAreaCharLimit="500"
                place-holder="Escreva a descrição do documento"
              />
            </v-flex>
            <div class="d-flex xs4 align-end px-3">
              <v-btn
                flat
                round
                color="white"
                class="btn-primary px-5 ml-auto mr-0"
                large
                @click="save()"
              >Salvar</v-btn>
            </div>
          </v-layout>
          <ul class="list-accountability">
            <li
              v-for="(goalValueFile, index) in goalValue.goalsValuesFiles"
              :key="goalValueFile.id"
            >
              <template v-if="goalValueFile.file">
                <i
                  :class="`fal fa-file-${
              getLastArray(goalValueFile.file.name.split('.')) == 'PDF' || getLastArray(goalValueFile.file.name.split('.')) == 'pdf' ? 'pdf': 'image'
            } text-red`"
                ></i>
                <h3 class="text-color-dark">{{goalValueFile.file.name}}</h3>
              </template>
              <template v-else>
                <i :class="`fal fa-file-video text-red`"></i>
                <h3 class="text-color-dark">{{goalValueFile.video.name}}</h3>
              </template>
              <a @click="removeFile(index)" class="ml-auto" title="Remover arquivo">
                <i class="fal fa-trash"></i>
              </a>
            </li>
          </ul>
        </v-container>
      </template>

      <!-- action buttons  -->
      <template v-slot:footer>
        <div class="d-flex xs7 px-3 align-center">
          <i class="fal fa-exclamation-triangle text-yellow alert-accountability"></i>
          Envie arquivos com nomes que indiquem seu conteúdo a fim de facilitar sua identificação.
        </div>
        <v-flex xs5>
          <div class="upload-bar-gallery w-100 pr-2">
            <label @click="openUploadVideo" class="ml-auto mr-4">
              <i class="fal fa-video"></i>
            </label>
            <label
              :for="goal.goalVerificationId != 3 ? 'upload-file2' : 'upload-file3'"
              class="ml-auto mr-4"
            >
              <i class="fal fa-cloud-upload"></i>
            </label>
            <input
              ref="fileUpload"
              type="file"
              :id="'upload-file2'"
              v-if="goal.goalVerificationId != 3"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .html, .xlsx, .xls, .csv"
              @change="fileChanged($event)"
            />
            <input
              ref="imageUpload"
              type="file"
              :id="'upload-file3'"
              v-else
              accept=".jpg, .jpeg, .png"
              @change="fileChanged($event)"
            />
          </div>
        </v-flex>
      </template>
    </Modal>
    <UploadVideo ref="videoComponent" @close="closeUploadVideo" @save="addVideo"></UploadVideo>
  </div>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";
import UploadVideo from "@/components/UploadVideo.vue";
import GoalsValues from "@/scripts/models/goalsValues.model";
import GoalsValuesFile from "@/scripts/models/goalsValuesFile.model";
import Goal from "@/scripts/models/goal.model";
import File from "@/scripts/models/file.model";
import Video from "@/scripts/models/video.model";
import ProjectService from "@/scripts/services/project.service.js";
export default {
  components: {
    Modal,
    UploadVideo
  },
  props: {
    indexFrequency: 0
  },
  data() {
    return {
      searchText: "",
      show: false,
      realized: "",
      goal: new Goal(),
      goalValue: new GoalsValues(),
      projectService: new ProjectService(),
      frequencyDesc: ""
    };
  },
  computed: {},
  methods: {
    open(goal, goalValue) {
      this.goal = goal;
      this.frequencyDesc = goalValue.frequencyDesc;
      this.loadGoalValue(goalValue.id);
      this.show = true;
    },
    close() {
      this.goalValue = new GoalsValues();
      this.goalValue.realized = 0;
      this.show = false;
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let newFile = new File();
      newFile.name = file.name;
      let reader = new FileReader();
      reader.onload = function() {
        newFile.path = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
      var goalsValuesFile = new GoalsValuesFile();
      goalsValuesFile.file = newFile;
      goalsValuesFile.video = null;
      goalsValuesFile.videoId = null;
      goalsValuesFile.goalValueId = this.goalValue.id;
      this.goalValue.goalsValuesFiles.push(goalsValuesFile);
      this.$refs.fileUpload.value=null;
      if(this.goal.goalVerificationId === 3)
        this.$refs.imageUpload.value=null;
    },
    addVideo(newVideo) {
      var goalsValuesFile = new GoalsValuesFile();
      goalsValuesFile.file = null;
      goalsValuesFile.fileId = null;
      goalsValuesFile.video = newVideo;
      goalsValuesFile.goalValueId = this.goalValue.id;
      this.goalValue.goalsValuesFiles.push(goalsValuesFile);
      this.show = true;
    },
    openUploadVideo() {
      this.$refs.videoComponent.open();
      this.show = false;
    },
    closeUploadVideo() {
      this.show = true;
    },
    loadGoalValue(id) {
      this.projectService.findGoalValueById(this.callbackFindGoalValueById, id);
    },
    callbackFindGoalValueById(data) {
      if (data != null) {
        this.goalValue = data;
      }
    },
    copy(goalValue) {
      this.goalValue = new GoalsValues();
      this.goalValue.id = goalValue.id;
      this.goalValue.goalId = goalValue.goalId;
      this.goalValue.order = goalValue.order;
      this.goalValue.value = goalValue.value;
      this.goalValue.statusId = goalValue.statusId;
      this.goalValue.initialDate = goalValue.initialDate;
      this.goalValue.finalDate = goalValue.finalDate;
      this.goalValue.realized = goalValue.realized;
      this.goalValue.canceled = goalValue.canceled;
      this.goalValue.title = goalValue.title;
      this.goalValue.description = goalValue.description;
      this.goalValue.sentNotification = goalValue.sentNotification;
      this.goalValue.goalsValuesFiles = goalValue.goalsValuesFiles;
    },
    save() {
      if (
        !this.goalValue.description ||
        this.goalValue.description.length < 50
      ) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "A descrição precisa ter pelo menos 50 caracteres."
        );
      } else if (!this.goalValue.title || this.goalValue.title.length < 5) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "A título precisa ter pelo menos 5 caracteres."
        );
      } else if (this.goalValue.realized < 0) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Valor realizado não pode ser menor que 0.."
        );
      } else {
        this.projectService.saveGoalValue(this.saveCallback, this.goalValue);
      }
    },
    saveCallback() {
      this.$emit("save");
      this.close();
    },
    removeFile(index) {
      this.goalValue.goalsValuesFiles.splice(index, 1);
    },
    getLastArray(array, n) {
      if (array == null) return void 0;
      if (n == null) return array[array.length - 1];
      return array.slice(Math.max(array.length - n, 0));
    }
  }
};
</script>