<template>
  <div>
    <div class="wrapper">
      <v-layout class="d-flex flex-column mb-5">
        <v-flex>
          <v-layout w-100 align-center>
            <v-flex shrink pr-5>
              <h2 class="text-gray-dark">{{ schedule.name }}</h2>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex mt-2 class="goal-line w-100 justify-content-between">
          <v-layout
            wrap
            class="period-content"
            v-for="period in schedule.schedulePeriodList"
            :key="period.id"
          >
            <v-flex md4 xs12 pr-2 style="display:flex;align-items:center;">
              <h5
                v-if="period.finalDate"
              >{{period.initialDate | FormatDate('DD/MM/YYYY') }} - {{period.finalDate | FormatDate('DD/MM/YYYY') }}</h5>
              <h5 v-else>{{period.initialDate | FormatDate('DD/MM/YYYY') }}</h5>
            </v-flex>

            <v-flex md5 xs7 pr-2 style="display:flex;justify-content:center;">
              <div class="period-status">
                <div
                  class="mr-2"
                  :class="getSchedulePeriodStatusClass(period.status)"
                >{{getSchedulePeriodStatus(period.status)}}</div>
              </div>
            </v-flex>

            <v-flex md3 xs5 pr-2 style="display:flex;justify-content:flex-end;">
              <div
                class="period-status"
                v-if="period.status == schedulePeriodStatus.Scheduled || period.status == schedulePeriodStatus.Late || period.status == schedulePeriodStatus.Ontime"
              >
                <button class="finished mr-2" @click="openAccountabilityModal(period)"><i class="fal fa-check"></i></button>
                <button class="canceled mr-2" @click="confirmChangeToCanceled(period)"><i class="fal fa-times"></i></button>
              </div>
              <div
                class="period-status"
                v-if="period.status == schedulePeriodStatus.Finished"
              >
                <button class="scheduled mr-2" @click="openAccountabilityModal(period)"><i class="fal fa-pen"></i></button>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <AucAlert
      ref="alertComponentChangeCanceled"
      :title="'Confirma a alteração dessa atividade para cancelado?'"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmedChangeToCanceled()"
    />
  </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import { format } from "date-fns";
import AucAlert from "@/components/AucAlert.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import ProjectService from "@/scripts/services/project.service";
import {
  SchedulePeriodStatus,
  GetSchedulePeriodStatus,
  GetSchedulePeriodStatusClass
} from "@/scripts/models/enums/schedulePeriodStatus.enum";

export default {
  components: {
    AucAlert,
    Modal
  },
  props: ["schedule"],
  data() {
    return {
      valueDeterminate: 10,
      GetGoalFrequencyName: GetGoalFrequencyName,
      formatterHelper: new FormatterHelper(),
      projectService: new ProjectService(),
      schedulePeriodStatus: SchedulePeriodStatus,
      selectedSchedulePeriod: null,
      getSchedulePeriodStatus: GetSchedulePeriodStatus,
      getSchedulePeriodStatusClass: GetSchedulePeriodStatusClass
    };
  },
  created() {
    this.schedulePeriodStatus = SchedulePeriodStatus;
    this.getSchedulePeriodStatus = GetSchedulePeriodStatus;
    this.getSchedulePeriodStatusClass = GetSchedulePeriodStatusClass;
    this.projectService = new ProjectService();
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    openAccountabilityModal(item) {
      this.$emit("accountability", item);
    },
    confirmChangeToCanceled(item) {
      this.selectedSchedulePeriod = item;
      this.$refs.alertComponentChangeCanceled.open();
    },
    confirmedChangeToFinished() {
      this.projectService
        .changeSchedulePeriodStatus(
          this.selectedSchedulePeriod.id,
          this.schedulePeriodStatus.Finished
        )
        .then(
          function(data) {
            if (data.success) {
              var index = this.schedule.schedulePeriodList.findIndex(
                item => item.id == this.selectedSchedulePeriod.id
              );
              this.schedule.schedulePeriodList[
                index
              ].status = this.schedulePeriodStatus.Finished;
            }
          }.bind(this)
        );
    },
    confirmedChangeToCanceled() {
      this.projectService
        .changeSchedulePeriodStatus(
          this.selectedSchedulePeriod.id,
          this.schedulePeriodStatus.Canceled
        )
        .then(
          function(data) {
            if (data.success) {
              var index = this.schedule.schedulePeriodList.findIndex(
                item => item.id == this.selectedSchedulePeriod.id
              );
              this.schedule.schedulePeriodList[
                index
              ].status = this.schedulePeriodStatus.Canceled;
            }
          }.bind(this)
        );
    },
    confirm() {
      this.$emit("remove", this.selectedGoal);
    }
  }
};
</script>