<template>
  <v-flex fluid py-3>
    <div
      class="upload-hero bg-contain"
      id="preview"
      ref="heroImage"
      :style="'backgroundImage:' + getImage()"
    >
      <label for="upload-hero" class="do-upload">
        <i class="fal fa-cloud-upload"></i>
      </label>
      <input type="file" id="upload-hero" accept="image/*" @change="heroImageChanged" />
    </div>
    <v-layout py-3 row wrap mw-total>
      <v-flex>
        <InputText
          ref="titleInput"
          type="text"
          textLabel="Chamada"
          v-model="projectHero.primaryText"
          helpLabel="Ex: Mais de mil crianças já foram para a escola."
          place-holder="Escreva uma chamada que atraia a atenção do público"
          labelCharLimit="100"
        />
        <InputText
          ref="descriptionInput"
          type="text"
          textLabel="Texto de Apoio"
          v-model="projectHero.supportText"
          helpLabel="Acompanha o andamento do projeto, você faz parte dele."
          place-holder="No texto de apoio você explica a intenção da página"
          labelCharLimit="300"
        />
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script type="plain/text">
import CommonHelper from "@/scripts/helpers/common.helper";
import ProjectAccountabilityHeroConfig from "@/scripts/models/projectAccountabilityHeroConfig.model";

export default {
  components: {},
  props: ["projectHero"],
  data() {
    return {};
  },
  methods: {
    getImage() {
      if (this.projectHero != null && this.projectHero.heroFile != null)
        return "url(" + this.projectHero.heroFile.path + ")";
      return "";
    },
    heroImageChanged(event) {
      var file = event.target.files[0];
      this.projectHero.heroFile.name = file.name;
      let reader = new FileReader();
      reader.onload = function() {
        this.projectHero.heroFile.path = reader.result;
        this.projectHero.heroFile.imageContent = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
    }
  }
};
</script>