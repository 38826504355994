<template>
  <div>
    <div class="wrapper mb-5">
      <v-layout>
        <v-flex mt-2>
          <div class="goal-line w-100 d-flex flex-column">
            <v-layout
              v-for="goalValue in filteredGoalValues"
              :key="goalValue.id"
              class="d-flex flex-column"
            >
              <v-layout class="first-line" wrap>
                <v-flex xs12 sm4 lg6>
                  <h4>
                    <b>{{ goalValue.frequencyDesc }}</b>
                  </h4>
                  <h4>Início: {{goalValue.initialDate | FormatDate("L") }}</h4>
                  <h4>Fim: {{goalValue.finalDate | FormatDate("L") }}</h4>
                </v-flex>

                <v-flex xs12 sm4 lg6 class="text-xs-right">
                  <v-btn
                    text
                    v-if="showNotificationButton(goalValue)"
                    @click="openEmailConfirm(goalValue)"
                    class="accountability-button"
                  >
                    <v-icon size="20" class="mr-2">fal fa-paper-plane</v-icon>Notificar Doadores
                  </v-btn>
                  <v-btn
                    text
                    v-else-if="checkAlreadySent(goalValue)"
                    :disabled="checkAlreadySent(goalValue)"
                    @click="openEmailConfirm(goalValue)"
                    class="accountability-button"
                  >
                    <v-icon size="20" class="mr-2">fal fa-paper-plane</v-icon>Enviado {{goalValue.sentNotificationDate | FormatDate("DD/MM/YYYY hh:mm")}}
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-flex>
                <v-progress-linear
                  :buffer-value="formatterHelper.formatSlider(0, 100)"
                  height="3"
                  class="primary-accountability"
                ></v-progress-linear>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <AucAlert
      ref="emailConfirmComponent"
      title="Se prosseguir, você irá notificar todos os doadores que houve prestação de contas. Certifique-se que todas as metas e atividades referentes ao período já tenham sido prestadas, pois está ação poderá ser realizada somente uma única vez. Tem certeza que deseja continuar?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="sendEmail"
    />
  </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import { format } from "date-fns";
import {
  GoalFrequency,
  GetGoalFrequencyName
} from "@/scripts/models/enums/goalFrequency.enum";
import AucAlert from "@/components/AucAlert.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import Permissions from "@/scripts/models/enums/permissions.enum";
import {
  GoalsValuesStatus,
  GetGoalsValuesDescription,
  GetGoalsValuesStatusClass
} from "@/scripts/models/enums/goalsValuesStatus.enum";
import ProjectService from "@/scripts/services/project.service";
export default {
  components: {
    AucAlert,
    Modal
  },
  props: ["goalList", "frequency"],
  data() {
    return {
      valueDeterminate: 10,
      selectedGoal: null,
      selectedEmailGoal: null,
      GoalsValuesStatus: GoalsValuesStatus,
      GetGoalsValuesDescription: GetGoalsValuesDescription,
      GetGoalsValuesStatusClass: GetGoalsValuesStatusClass,
      GetGoalFrequencyName: GetGoalFrequencyName,
      formatterHelper: new FormatterHelper(),
      projectService: new ProjectService()
    };
  },
  computed: {
    goal() {
      if (this.goalList && this.goalList.length > 0) return this.goalList[0];
      return null;
    },
    filteredGoalValues() {
      if (this.goal && this.goal.goalsValuesList)
        return this.goal.goalsValuesList.filter(x => !x.canceled);
      return [];
    }
  },
  methods: {
    showNotificationButton(goalValue) {
      for (let i = 0; i < this.goalList.length; i++) {
        if (this.goalList[i].goalsValuesList) {
          let hasFinished = this.goalList[i].goalsValuesList.find(
            current =>
              current.initialDate === goalValue.initialDate &&
              current.finalDate === goalValue.finalDate &&
              current.statusId == GoalsValuesStatus.Finished &&
              !current.sentNotification
          );
          if (hasFinished) return true;
        }
      }
      return false;
    },
    checkAlreadySent(goalValue) {
      for (let i = 0; i < this.goalList.length; i++) {
        if (this.goalList[i].goalsValuesList) {
          let hasSent = this.goalList[i].goalsValuesList.find(
            current =>
              current.initialDate === goalValue.initialDate &&
              current.finalDate === goalValue.finalDate &&
              current.statusId == GoalsValuesStatus.Finished &&
              current.sentNotification
          );
          if (hasSent) return true;
        }
      }
      return false;
    },
    openEmailConfirm(goalValue) {
      this.selectedEmailGoal = goalValue;
      this.$refs.emailConfirmComponent.open();
    },
    sendEmail() {
      this.projectService
        .sendEmailNotificationToDonatorByGoal(this.selectedEmailGoal.id)
        .then(data => {
          this.$emit("refresh");
        });
    }
  }
};
</script>